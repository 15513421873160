import React, { useContext, useEffect } from 'react';
import { Grid, Row, Col } from 'react-flexbox-grid';
import ScrollableAnchor, { goToAnchor } from 'react-scrollable-anchor';
import BrandCTA from 'components/BrandCta';
import SourceEmitter from 'libs/emitter';
import { appContext } from 'providers/appProvider';
import { Layout, Seo } from 'components';
import ContentBlock from 'components/Content/ContentBlock';
import ColorBlock from 'components/Content/ColorBlock';
import TableBlock from 'components/Content/TableBlock';

import './b12-deficiency.scss';

import b12DefficiencyHeroMobile from '../../assets/images/nascobal-pat-b12-defficiency-hero-mobile.png';

const B12deficiencyPage = () => {
  let Subscription = null;
  const { appConfigs } = useContext(appContext);

  const indication = appConfigs?.siteOptions?.INDICATIONS?.patient;
  // const metaData = appConfigs?.metaData?.patient;

  const triggerHashChange = () => {
    const currentHash = window?.location?.hash?.substring(
      1,
      window?.location?.hash?.length,
    );
    SourceEmitter.emit('HashChangedEventTrigger', currentHash);
  };

  useEffect(() => {
    window.addEventListener('hashchange', triggerHashChange, false);
    // Triggered from Logo Component
    Subscription = SourceEmitter.addListener('gotoTopTriggerEvent', data => {
      goToAnchor('top');
    });
    return () => {
      window.removeEventListener('hashchange', triggerHashChange, false);
      Subscription && Subscription.remove();
    };
  }, []);

  const brandNames = {
    NASCOBAL: appConfigs?.metaData?.brandNames?.nascobal,
    BARIACTIV: appConfigs?.metaData?.brandNames?.bariactiv,
    NUTRITION: appConfigs?.metaData?.brandNames?.nutrition,
  };

  const tableData = [
    [
      'FDA-approved',
      'Prescription',
      'Once-a-week dosing',
      'Odorless',
      'Tasteless',
      'Can be taken at home',
      'Not an injection',
    ],
    ['Once-a-day dosing', 'Can be taken at home', 'Not an injection'],
    [
      'FDA-approved',
      'Prescription',
      'Daily to monthly dosing',
      'Odorless',
      'Tasteless',
    ],
  ];

  const pageTitle = 'NASCOBAL® | What Is Vitamin B12 Deficiency?';
  const pageDescription =
    'Find common causes and symptoms and vitamin B12 options';

  const renderPage = () => {
    return (
      <div id="nascobal-patient-b12-deficiency">
        <ContentBlock>
          <Grid
            fluid
            style={{
              paddingLeft: 0,
              paddingRight: 0,
              paddingTop: 0,
            }}
          >
            <Row>
              <Col xs={12}>
                <div id="hero" className="hide-in-small">
                  <h2>
                    What is Vitamin B<sub className="vitaminb12-sub">12</sub>{' '}
                    <br className="hide-only-mobile" />
                    deficiency?
                  </h2>
                </div>
                <div id="hero-mobile" className="show-in-small">
                  <img src={b12DefficiencyHeroMobile} />
                </div>
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={12}>
                <ColorBlock white>
                  <h2 className="strong">
                    Why vitamin B<sub className="vitaminb12-sub">12</sub> is
                    important
                  </h2>
                  <p>
                    Vitamin B<sub className="vitaminb12-sub">12</sub> is an
                    essential vitamin. Everyone needs it to stay healthy.
                    Vitamin B<sub className="vitaminb12-sub">12</sub> helps your
                    body work properly. It protects your nerve cells so they can
                    grow the right way. Vitamin B
                    <sub className="vitaminb12-sub">12</sub> also helps make red
                    blood cells, which give oxygen to the tissues in your body.
                  </p>
                  <h2 className="strong top-spacer">
                    There are many causes for vitamin B
                    <sub className="vitaminb12-sub">12</sub> deficiency
                  </h2>
                  <Row>
                    <Col xs={12} md={6}>
                      <ul className="listing">
                        <li>Celiac disease</li>
                        <li>Alcohol abuse</li>
                        <li>Strict vegetarian or vegan diet</li>
                        <li>
                          Long-term use of certain medications such as{' '}
                          <br className="hide-mobile" /> metformin and proton
                          pump inhibitors
                        </li>
                        <li>Immune system disorders</li>
                      </ul>
                    </Col>
                    <Col xs={12} md={6}>
                      <ul>
                        <li>HIV infection</li>
                        <li>Crohn’s disease</li>
                        <li>Atrophic gastritis</li>
                        <li>Gastric surgery</li>
                        <li>Hypothyroidism</li>
                      </ul>
                    </Col>
                  </Row>
                </ColorBlock>
              </Col>
              <Col xs={12} md={12}>
                <ColorBlock blue>
                  <h2 className="strong">
                    Symptoms of vitamin B
                    <sub className="vitaminb12-sub">12</sub> deficiency are
                    non-specific
                  </h2>
                  <p className="orange">Symptoms may include:</p>
                  <Row>
                    <Col xs={12} md={6}>
                      <ul className="listing">
                        <li>Diarrhea or constipation</li>
                        <li>Fatigue, muscle weakness</li>
                        <li>Loss of appetite</li>
                        <li>Pale skin</li>
                        <li>Memory loss</li>
                      </ul>
                    </Col>
                    <Col xs={12} md={6}>
                      <ul>
                        <li>Shortness of breath</li>
                        <li>Swollen, red tongue</li>
                        <li>Numbness and tingling of hands and feet</li>
                        <li>Confusion or change in mental status</li>
                        <li>Loss of balance</li>
                      </ul>
                    </Col>
                  </Row>
                </ColorBlock>
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <ColorBlock white>
                  <h2 className="strong">
                    Talk to your doctor about being screened for vitamin B
                    <sub className="vitaminb12-sub">12</sub> deficiency
                  </h2>
                  <p>
                    If you have been diagnosed with vitamin B
                    <sub className="vitaminb12-sub">12</sub> deficiency, there
                    are many ways to receive supplementation. Here are some of
                    the common options:
                  </p>
                </ColorBlock>
                <ColorBlock white id="colorblock-patient-resources">
                  <Row>
                    <Col xs={12} md={4}>
                      <TableBlock
                        headBgColor="#fbaf40"
                        dataList={tableData[0]}
                        tableTitle={brandNames.NASCOBAL + '<br/>Nasal Spray'}
                      />
                    </Col>
                    <Col xs={12} md={4}>
                      <TableBlock
                        headBgColor="#e6f2fb"
                        dataList={tableData[1]}
                        tableTitle={'SUBLINGUAL<br/>(under-the-tongue)'}
                      />
                    </Col>
                    <Col xs={12} md={4}>
                      <TableBlock
                        headBgColor="#f3f3f3"
                        dataList={tableData[2]}
                        tableTitle={'INJECTIONS<br/>&nbsp;'}
                      />
                    </Col>
                  </Row>
                </ColorBlock>
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={6}>
                <BrandCTA
                  href="/vitaminb12/resources"
                  LinkCaption="DOWNLOAD PATIENT RESOURCES"
                >
                  DOWNLOAD PATIENT RESOURCES
                </BrandCTA>
              </Col>
              <Col xs={12} md={6}>
                <BrandCTA
                  href="/vitaminb12/about"
                  LinkCaption="LEARN HOW NASCOBAL® MAY HELP"
                >
                  LEARN HOW NASCOBAL
                  <sup className="brand-reg-mark">&reg;</sup> MAY HELP
                </BrandCTA>
              </Col>
            </Row>
          </Grid>
        </ContentBlock>
        <ScrollableAnchor id="isi_anchor">
          <div />
        </ScrollableAnchor>
      </div>
    );
  };

  return (
    <Layout indication={indication} className="patient-b12deficiency-page">
      {/* <Seo {...metaData} /> */}
      <Seo pageTitle={pageTitle} pageDescription={pageDescription} />
      <div className="body-content-container">
        <div className="body-content gutter-all">{renderPage()}</div>
      </div>
    </Layout>
  );
};

export default B12deficiencyPage;
